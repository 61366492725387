import React from "react"
import { Link} from 'gatsby';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Fade } from 'react-awesome-reveal';
import LetsWorkTogether from '../components/letsWorkTogether';

const IndividualFolio = ({pageContext}) => (
<Layout>

{pageContext.yoast_meta.map((meta,key) => 
	<>
	{meta.name === 'description' &&
	<SEO description={meta.content} title={pageContext.title} />		
	}
	</>
)}

<main id="main">
	<nav aria-label="breadcrumb" className="breadcrumbs-section border-top border-bottom mb-0">
		<div className="container">
			<ol className="breadcrumb mb-0 py-2 px-0">
				<li className="breadcrumb-item"><a href="/">Home</a></li>
				<li className="breadcrumb-item"><a href="/folio">Folio</a></li>
				<li className="breadcrumb-item active" dangerouslySetInnerHTML={{ __html:pageContext.title}} />
			</ol>
		</div>
	</nav>
		<section className="intro-section pt-10 pb-5 pt-lg-15 pb-lg-8 ">
			<div className="container">
				<div className="row align-items-center justify-content-between case-study-intro pb-5">
					<div className="col-12 col-md-7 mb-8 mb-md-0"><h1 className="mb-8"  dangerouslySetInnerHTML={{ __html:pageContext.acf.tagline}} />
						<div className="row case-study-info"><div className="col-6"> 
							<strong className="h6 text-fade-black mb-0 mb-md-0 fw-500">Client</strong> <span>{pageContext.acf.client_name}</span></div>
							<div className="col-6"> 
								<strong className="h6 text-fade-black mb-0 mb-md-0 fw-500">Project</strong> 
								<span>{pageContext.acf.project_name}</span>
							</div>
							<div className="col-12 mt-6">
								<div className="list-inline list-tags tag-list">
									<div className="list-inline-item mb-2">
									
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-5 text-center text-md-right d-print-none">
					<div className="img-holder"> 
						<img src={`${pageContext.acf.image.url.localFile.publicURL}`} alt="" width="" height="" />
					</div>
				</div>
			</div>
			</div>
		</section>
		

		
		<Fade triggerOnce="true" direction="top" className="container d-print-none">
			<div className="row align-items-center">
				<div className="col-md-8 mb-md-30 mb-10">
					<h2 className="h2 fw-700 mt-md-30 mt-10">Overview</h2>
					<div dangerouslySetInnerHTML={{ __html: pageContext.content }}/>
				</div>
			</div>
		</Fade>

		{pageContext.acf.challenge_images &&
		<Fade triggerOnce="true" direction="top" className="d-print-none">
			<div className="row align-items-center">
				<div className="horizontal-slider">
				
				{pageContext.acf.challenge_images.map((challenge_image,key) => 
					
					
					
						<div className="slider">
							<img style={{width: 100+'vw'}} src={pageContext.acf.challenge_images[key].challenge_image.localFile.publicURL} alt="" />
						</div>
					
					
				)} 
				</div>
			</div>
		</Fade>
		}
		
		<Fade triggerOnce="true" direction="top" className="container d-print-none">
			<div className="row align-items-center">
				<div className="col-md-8 mt-10 mt-md-30 mb-md-30 mb-10">
					<h2 className="h2 fw-700">Challenge</h2>
					<div dangerouslySetInnerHTML={{ __html: pageContext.acf.the_challenge}}/>
				</div>
			</div>
		</Fade>

		{pageContext.acf.solution_images &&
		<Fade triggerOnce="true" direction="top" className="d-print-none">
			<div className="row align-items-center">
				<div className="horizontal-slider2">
				{pageContext.acf.solution_images.map((solution_image,key) => 
					<>
					{pageContext.acf.solution_images[key].solution_image &&
					<div className="slider">
						<img style={{width: 100+'vw'}} src={pageContext.acf.solution_images[key].solution_image.localFile.publicURL}  alt="" />
					</div>
					}
					</>	
				)} 
				</div>
			</div>
		</Fade>
		}

		{pageContext.acf.the_solution &&
		<Fade triggerOnce="true" direction="top" className="container d-print-none">
			<div className="row align-items-center">
				<div className="col-md-8 mt-10 mb-10 mt-md-30 mb-md-10">
					<h2 className="h2 fw-700 ">Solution</h2>
					<div dangerouslySetInnerHTML={{ __html: pageContext.acf.the_solution}} />
				</div>
			</div>
		</Fade>
		}


		{pageContext.acf.result_images &&
			<Fade triggerOnce="true" direction="top" className="d-print-none">
				<div className="row align-items-center">
					<div className="horizontal-slider2">
					{pageContext.acf.result_images.map((solution_image,key) => 
						<>
						{pageContext.acf.result_images[key].result_image &&
						<div className="slider">
							<img style={{width: 100+'vw'}} src={pageContext.acf.result_images[key].result_image.localFile.publicURL}  alt="" />
						</div>
						}
						</>	
					)} 
					</div>
				</div>
			</Fade>
			}

		{pageContext.acf.the_result &&
		<Fade triggerOnce="true" direction="top"iv className="container d-print-none">
			<div className="row align-items-center">
				<div className="col-md-8">
					<h2 className="h2 fw-700 mt-md-10">The result</h2>
					<div dangerouslySetInnerHTML={{ __html: pageContext.acf.the_result}} />
				</div>
			</div>
		</Fade>
		}
		{pageContext.acf.website_url &&
		<Fade triggerOnce="true" direction="top" className="text-center mb-10 mt-10"><a href={pageContext.acf.website_url} target="_blank" rel="noreferrer" className="btn btn-primary">Launch Project</a></Fade>
		}
		<div className="position-relative mb-14">
			<div className="container">
		
				<LetsWorkTogether tagline={pageContext.acf.lets_work_tagline }  buttonText={pageContext.acf.lets_work_button} />
			</div>
		</div>
		{pageContext.acf.related_projects &&
			<section className="related-case-studies article-section viewport-section">
				<div className="container">
					<div className="border-top py-10 py-md-12 pb-lg-16 pb-16">
						<h2 className="section-heading text-default text-center mb-5 mb-md-12 fw-700 fadeInUp">Related projects</h2>
						<div className="article-list position-relative row fadeInUp pb-10">
						{pageContext.acf.related_projects.map((post,key) => (
						<Fade triggerOnce="true" direction="top" className="col-12 col-md-6 col-lg-4 position-relative mb-5 mb-md-0 post-item" key={key}>
							<div className="card"> 
								<a href={`/folio/${post.post_name}`} className="card-img-top d-block position-relative"> 
								<strong className="sr-only">This is a card image link</strong> 
								<img src={`${post.acf.image.url.localFile.publicURL}`} alt="" width="" height="" /></a>
								<div className="card-body"> 
									<span className="category d-block mb-2 fw-500 text-fade-black">{post.post_title}</span>
									<h3 className="card-title fw-700 text-blue"><Link to={`/folio/${post.post_name}`}>{post.acf.tagline}</Link></h3>
									<p className="card-text" dangerouslySetInnerHTML={{ __html: post.acf.intro }}/>
								</div>
							</div>
						</Fade>
						))}
						</div>
						</div>
					</div>
			</section>
			}
		</main>
	</Layout>

)

export default IndividualFolio	